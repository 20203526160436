.segmented-control {
  align-items: center;
  background-color: rgba(245, 246, 247, 1);;
  border-radius: 8px;
  display: flex;
  height: 44px;
  min-width: 262px;
  padding: 0 2px;
}

.roboto-medium-outer-space-14px {
  color: rgba(45, 55, 72, 1);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}
.option {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #0000000d;
  display: flex;
  height: 40px;
  min-width: 128px;
  padding: 0 12.2px;
  cursor: pointer
}

.label {
  letter-spacing: 0;
  margin-left: 10px;
  min-height: 16px;
  min-width: 69px;
  text-align: center;
}

.option-1 {
  align-items: center;
  background-color: rgba(245, 246, 247, 1);;
  border-radius: 6px;
  display: flex;
  height: 36px;
  margin-left: 3px;
  min-width: 106px;
  padding: 0 18.8px;
  cursor: pointer
}

.label-1 {
  color: rgba(119, 125, 133, 0.161);
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  min-width: 68px;
  text-align: center;
  white-space: nowrap;
}
